.input {
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  padding: 0 20px;
}

.lodestone-link::after {
  display: inline-block; /*忘れずに！*/
  content: url('./link.svg');
  vertical-align: middle;
  color: #ffffff;
  width: 1em;
  height: 1em;
  margin-right: 4px;
}

.description {
  font-size: 0.85em;
  word-wrap: normal;
}

.description::before {
  content: '\A';
  white-space: pre;
}

.alert li {
  display: block;
  color: #ff4955;
  margin-bottom: 10px;
}
