.canvas-wrapper {
  margin: 0 auto;
  z-index: 1;
}

@media screen and (max-width: 500px) {
  .canvas-wrapper {
    position: sticky;
    top: 0;
  }
}

.stage {
  max-width: 1200px;
  max-height: 630px;

  width: 100vw;
  height: 52.5vw;

  /*left: 50%;*/
  /*right: 50%;*/
  /*margin-left: -50vw;*/
  /*margin-right: -50vw;*/
}

.konvajs-content {
  max-width: 100%;
  max-height: 100%;
}

canvas {
  max-width: 100%;
  max-height: 100%;
}
