.customize {
  max-width: 768px;
  margin: 28px auto 20px;
  padding: 0 20px;
}

dl {
  line-height: 1.5;
}

dt {
  font-size: 1.1em;
  font-weight: bold;
  color: #ffe;
}

dd {
  margin-left: 0;
  margin-bottom: 15px;
}

@media screen and (min-width: 768px) {
  dt {
    float: left;
    clear: left;
    width: 150px;
  }

  dd {
    margin-left: 155px;
  }
}

ul {
  margin: 0;
  padding: 0;
}

li {
  display: inline;
  margin-right: 0.4em;
}

label {
  white-space: nowrap;
  display: inline-block;
}

label input {
  vertical-align: baseline;
}

label input[type='range'] {
  margin-left: 8px;
  vertical-align: middle;
  width: 150px;
}
