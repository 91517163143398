a {
  text-decoration: none;
  color: #88bbff;
}

a:visited {
  color: #88bbff;
}

a:hover {
  text-decoration: underline;
}

.App {
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
